import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../enum';

@Pipe({
	name: 'price',
})
export class PricePipe implements PipeTransform {
	transform(priceInPennies: number, currency: Currency = Currency.POLISH_ZLOTY): string {
		return new Intl.NumberFormat('pl-PL', {
			style: 'currency',
			currency: currency,
			currencyDisplay: 'code',
		}).format(priceInPennies / 100);
	}
}
