import { createAction, props } from '@ngrx/store';
import {
	MatProduct,
	OptionalTrip,
	DescriptionTemplate,
	SellTerm,
	MatPromotion,
	FilterValues,
	PriceSettings,
	SellTransport,
	Accommodation,
	TermDateRange,
} from '@/_store/current-offer/current-offer.types';
import { Descriptions, LocationDescriptions } from '@/offer/offer.model';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';

const KEY = '[CURRENT OFFER]';

// FETCHES

export const fetchMatProduct = createAction(`${KEY} fetch mat product`, props<{ id: number }>());
export const fetchMatProductSuccess = createAction(
	`${KEY} fetch mat product success`,
	props<{ product: MatProduct }>(),
);
export const fetchMatProductError = createAction(`${KEY} fetch mat product error`, props<{ error: Error }>());

export const fetchOptionalTrip = createAction(`${KEY} fetch optional trip`, props<{ locationIds: number[] }>());
export const fetchOptionalTripSuccess = createAction(
	`${KEY} fetch optional trip success`,
	props<{ optionalTrip: OptionalTrip[] }>(),
);
export const fetchOptionalTripError = createAction(`${KEY} fetch optional trip error`, props<{ error: Error }>());

export const fetchLocations = createAction(`${KEY} fetch location`, props<{ ids: number[] }>());
export const fetchLocationsSuccess = createAction(
	`${KEY} fetch location success`,
	props<{ locations: LocationDescriptions[] }>(),
);
export const fetchLocationsError = createAction(`${KEY} fetch location error`, props<{ error: Error }>());

export const fetchDescription = createAction(`${KEY} fetch description`);
export const fetchDescriptionSuccess = createAction(
	`${KEY} fetch description success`,
	props<{ descriptions: Descriptions[] }>(),
);
export const fetchDescriptionError = createAction(`${KEY} fetch description error`, props<{ error: Error }>());

export const fetchDescriptionTemplate = createAction(`${KEY} fetch description template`);
export const fetchDescriptionTemplateSuccess = createAction(
	`${KEY} fetch description template success`,
	props<{ descriptionTemplates: DescriptionTemplate[] }>(),
);
export const fetchDescriptionTemplateError = createAction(
	`${KEY} fetch description template error`,
	props<{ error: Error }>(),
);

export const fetchSellTerm = createAction(`${KEY} fetch sell term`, props<{ params: string }>());
export const fetchSellTermSuccess = createAction(`${KEY} fetch sell term success`, props<{ sellTerms: SellTerm[] }>());
export const fetchSellTermError = createAction(`${KEY} fetch sell term error`, props<{ error: Error }>());

export const fetchPriceSettings = createAction(`${KEY} fetch price settings`, props<{ id: SellTerm['id'] }>());
export const fetchPriceSettingsSuccess = createAction(
	`${KEY} fetch price settings success`,
	props<{ priceSettings: PriceSettings }>(),
);
export const fetchPriceSettingsError = createAction(`${KEY} fetch price settings error`, props<{ error: Error }>());

export const fetchSellTransport = createAction(`${KEY} fetch sell transport`, props<{ id: SellTerm['id'] }>());
export const fetchSellTransportSuccess = createAction(
	`${KEY} fetch sell transport success`,
	props<{ sellTransport: SellTransport[] }>(),
);
export const fetchSellTransportError = createAction(`${KEY} fetch sell transport error`, props<{ error: Error }>());

export const fetchAccommodation = createAction(`${KEY} fetch accommodation`, props<{ id: SellTerm['id'] }>());
export const fetchAccommodationSuccess = createAction(
	`${KEY} fetch accommodation success`,
	props<{ accommodation: Accommodation[] }>(),
);
export const fetchAccommodationError = createAction(`${KEY} fetch accommodation error`, props<{ error: Error }>());

export const fetchPromotions = createAction(
	`${KEY} fetch promotions`,
	props<{ params: string; dateRange: TermDateRange }>(),
);
export const fetchPromotionsSuccess = createAction(
	`${KEY} fetch promotions success`,
	props<{ promotions: MatPromotion[]; allPromotions: MatPromotion[] }>(),
);
export const fetchPromotionsError = createAction(`${KEY} fetch promotions error`, props<{ error: Error }>());

// UPDATE LOCAL STATE

export const updateFilterTerm = createAction(
	`${KEY} update filter term`,
	props<{ dateRange: FilterValues['dateRange'] }>(),
);

export const updateFilterParticipants = createAction(
	`${KEY} update filter participants`,
	props<{ participants: FilterValues['participants'] }>(),
);

export const updateFilterChildrenBirthday = createAction(
	`${KEY} update filter children birthday`,
	props<{
		childrenBirthday: FilterValues['participants']['childrenBirthday'];
	}>(),
);

export const updateFilterRoomType = createAction(
	`${KEY} update filter room type`,
	props<{ roomType: FilterValues['roomType'] }>(),
);

export const updateFilterStop = createAction(
	`${KEY} update filter stop`,
	props<{ transport: FilterValues['transport'] }>(),
);

export const updateFilterBoard = createAction(`${KEY} update filter board`, props<{ board: FilterValues['board'] }>());

export const updateFilterPromotion = createAction(
	`${KEY} update filter promotion`,
	props<{ promotion: FilterValues['promotion'] }>(),
);

export const addNightsAmountOption = createAction(
	`${KEY} add nights amount filter`,
	props<{ nightsAmountFilter: FilterValues['nightsAmountFilter'] }>(),
);
export const removeNightsAmountOption = createAction(`${KEY} remove nights amount filter`);

// ADDITIONAL ROOMS FOR LOGGED USER

export const addNewRoom = createAction(`${KEY} add new room`);

export const updateEditedRoom = createAction(`${KEY} update edited room`, props<{ editedRoom: string }>());

export const removeRoom = createAction(`${KEY} remove room`, props<{ roomName: string }>());

// CLEAR

export const clearCurrentOffer = createAction(`${KEY} clear current offer`);

export const clearFilterValue = createAction(`${KEY} clear filter value`, props<{ option: OfferDropdownItemMode }>());
