import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OptimizedImageOptions } from '@/offer/offer.model';

export interface OptimizationSettings {
	mode?: string;
	element?: HTMLElement | undefined;
}

@Pipe({
	name: 'optimizedImage',
})
export class OptimizedImagePipe implements PipeTransform {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}
	transform(value: string, pipeOptions?: OptimizationSettings): string {
		const url = new URL(value);

		const fileName = url.pathname.slice(1);
		const domain = url.origin;

		const options: OptimizedImageOptions = {
			key: fileName,
			edits: {
				toFormat: 'webp',
			},
		};

		if (pipeOptions && !!pipeOptions?.mode) {
			if (pipeOptions.mode === 'resize') {
				options.edits.resize = {
					width: 300,
					height: 400,
					fit: 'contain',
				};
			}
			if (pipeOptions.mode === 'optimize' && !!pipeOptions?.element) {
				const parentElementWidth = pipeOptions.element?.parentElement?.offsetWidth;

				if (!parentElementWidth) {
					const parentElement = pipeOptions.element?.parentElement;
					parentElement?.setAttribute('id', 'element-to-remove');
					this.document.getElementById('element-to-remove')?.remove();
				} else {
					options.edits.resize = {
						width: parentElementWidth,
					};
				}
			}
		}
		return `${domain}/${btoa(JSON.stringify(options))}`;
	}
}
