import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import {
	Accommodation,
	ActivePriceSettingOptions,
	DescriptionTemplate,
	DescriptionTemplateHeaders,
	DescriptionTemplateHeadersSource,
	FilterValues,
	Location,
	MatProduct,
	OfferDescription,
	Participants,
	PriceOption,
	PromoMethod,
	SellTerm,
	TermDateRange,
} from './current-offer.types';
import { formatOskarDate } from '@/_utils/datetime/format-oskar-date';
import { adultsOrChildrenSufix } from '@/_utils/oskar/adults-or-children-sufix';
import { Descriptions, Promotion, PromotionOfferDateRange } from '@/offer/offer.model';
import { Params } from '@angular/router';
import sanitize from 'sanitize-html';

export const roomTypesForActiveParticipantsCondition = (
	a: Accommodation,
	activeFilter: FilterValues,
	isSurcharge: boolean,
): boolean => {
	return (
		(isSurcharge &&
			a.adults >= activeFilter?.participants?.adults &&
			a.children === activeFilter?.participants?.children) ||
		(isSurcharge && a.adults >= activeFilter?.participants?.adults + activeFilter?.participants?.children) ||
		(!isSurcharge &&
			a.adults === activeFilter?.participants?.adults &&
			a.children === activeFilter?.participants?.children) ||
		(!isSurcharge && a.adults + a.children === activeFilter?.participants?.adults)
	);
};

export const dropdownItemOption = (
	option: OfferDropdownItemMode,
	filterValues: FilterValues,
	isLoggedIn = false,
	promotions?: Promotion[],
): string => {
	switch (option) {
		case OfferDropdownItemMode.MAT_TERM:
			if (!filterValues?.dateRange) {
				return null;
			}
			const { lower, upper, transportFrom, transportTo } = filterValues.dateRange;
			const from = formatOskarDate(new Date(transportFrom ?? lower));
			const to = formatOskarDate(new Date(transportTo ?? upper));
			const isFrom = transportFrom || lower;
			const isTo = transportTo || upper;
			return isFrom && isTo ? `${from} - ${to}` : null;
		case OfferDropdownItemMode.BOARDS:
			if (!filterValues?.board) {
				return null;
			}
			const { name: boardName } = filterValues.board;
			return !!boardName ? boardName : null;
		case OfferDropdownItemMode.DEPARTURE:
			if (filterValues?.transport?.ownTransport) {
				return 'Dojazd własny';
			}

			return filterValues?.transport?.stop?.location ?? null;
		case OfferDropdownItemMode.PARTICIPANTS:
			if (!filterValues?.participants) {
				return null;
			}
			const text = adultsOrChildrenSufix(filterValues.participants);
			return !!text ? text : null;
		case OfferDropdownItemMode.ROOM_TYPES:
			if (!filterValues?.roomType) {
				return null;
			}
			const { name: roomName, roomTypeCount } = filterValues.roomType;
			return !!roomName ? (isLoggedIn ? `${roomName} (${roomTypeCount})` : roomName) : null;
		case OfferDropdownItemMode.PROMOTIONS:
			if (!filterValues?.promotion) {
				return null;
			}
			const availablePromotionsForOffer = findAvailablePromotionsForOffer(promotions, filterValues.dateRange);
			if (!availablePromotionsForOffer?.length) {
				return null;
			}
			const filterPromotionAvailable = findFilterPromotionAvailableForOffer(
				availablePromotionsForOffer,
				filterValues.promotion.id,
			);
			const setPromotion = filterPromotionAvailable
				? filterPromotionAvailable
				: availablePromotionsForOffer.length > 1
					? findBestPromo(availablePromotionsForOffer)
					: availablePromotionsForOffer[0];
			const { name: promotionName } = setPromotion.catalog;
			const { method, methodValue } = setPromotion;
			const sufix = method === PromoMethod.PERCENTAGE ? ` ${methodValue / 10}%` : ` -${methodValue / 10}zł`;
			return !!promotionName ? promotionName + sufix : null;
		default:
			const value: never = option;
			throw new Error(`There is no option like ${value}`);
	}
};

export const calcPromo = (
	price: number,
	filterValue: FilterValues,
	promotions?: Promotion[],
	dateRange?: TermDateRange,
): number => {
	if (!filterValue.promotion) {
		return price;
	}
	const selectedPromotions: Promotion[] = dateRange ? promotions : [filterValue.promotion];
	const selectedDateRange: TermDateRange = dateRange ?? filterValue.dateRange;
	const availablePromotionsForOffer = findAvailablePromotionsForOffer(selectedPromotions, selectedDateRange);

	if (!availablePromotionsForOffer?.length) {
		return price;
	}

	const filterPromotionAvailable = findFilterPromotionAvailableForOffer(
		availablePromotionsForOffer,
		filterValue.promotion.id,
	);

	if (filterPromotionAvailable) {
		return getDiscount(filterPromotionAvailable, price);
	}

	const bestPromotionForOffer = findBestPromo(availablePromotionsForOffer);
	return bestPromotionForOffer ? getDiscount(bestPromotionForOffer, price) : price;
};

export const calcChildrenDiscount = (price: number, childrenDiscount: ActivePriceSettingOptions): number =>
	childrenDiscount
		? childrenDiscount.priceOption.method === PriceOption.PERCENTAGE && childrenDiscount.methodValue > 0
			? price * (1 - childrenDiscount.methodValue / 1000)
			: price - childrenDiscount.methodValue
		: price;

export const calcTransportPrice = (filterValue: FilterValues, ownTransport: ActivePriceSettingOptions): number =>
	filterValue?.transport
		? filterValue.transport.ownTransport
			? ownTransport?.methodValue ?? 0
			: filterValue.transport.stop?.totalFee ?? 0
		: 0;

export const findPromo = (promotions: Promotion[], queryParams: Params): Promotion => {
	const promoBasedOnParam = queryParams['promotion']
		? promotions?.find((promo) => promo.id === +queryParams['promotion'])
		: null;

	return promoBasedOnParam
		? promoBasedOnParam
		: promotions?.reduce((acc, promo) => {
				if (promo.method === PromoMethod.PERCENTAGE && (acc === null || acc.methodValue < promo.methodValue)) {
					acc = promo;
				}
				return acc;
			}, null);
};

export const findBestTerm = (sellTerms: SellTerm[]): SellTerm => {
	let bestTerm = sellTerms[0];
	for (let i = 1; i < sellTerms.length; i++) {
		if (bestTerm.termPrice > sellTerms[i].termPrice) {
			bestTerm = sellTerms[i];
		}
	}
	return bestTerm;
};

// **
// * First of all we are using termId cause it solves some errors, and indicates specific term.
// * But old configurator, and some components are using data_range param, so I left it.
// * If there isn't termId, then we're searching the term by date_range.
// * When offer-service removed we can try to remove that date_range from urls.
// **
export const findParamsTerm = (sellTerms: SellTerm[], queryParams: Params): SellTerm => {
	const termParam = queryParams['date_range'];
	const termId = queryParams['term_id'];
	let foundTerm = null;

	if (!termId && !termParam) {
		return null;
	}

	if (termId) {
		foundTerm = sellTerms.find((term) => term.id === Number(termId));
	}

	if (foundTerm) {
		return foundTerm;
	}

	const [lower, upper] = Array.isArray(termParam) ? termParam[0] : !!termParam ? termParam.split(',') : '';
	foundTerm = sellTerms.find((term) => term.dateRange.lower === lower && term.dateRange.upper === upper);

	if (!foundTerm) {
		return null;
	}

	return foundTerm;
};

export const adultsParticipantsWhenSurchargeService = (roomTypes: Accommodation[]): number[] => {
	const maxParticipants = roomTypes.reduce((acc, roomType) => {
		if (roomType.adults > acc) {
			acc = roomType.adults;
		}

		return acc;
	}, 0);

	const arr = [];

	for (let i = 1; i <= maxParticipants; i++) {
		arr.push(i);
	}

	return arr;
};

export const calcRoomPrice = (
	adultPrice: number,
	childPrice: number,
	surcharge: number,
	filterValue: FilterValues,
): number => {
	if (!filterValue || !filterValue.participants || !filterValue.dateRange) {
		return null;
	}

	const { adults, children } = filterValue.participants;

	return adults * adultPrice + children * childPrice + surcharge;
};

export const findBestRoom = (accommodation: Accommodation[], participants: Participants): Accommodation => {
	// perfect room is when number of participants is the same, and the name fits to `Pokój ${participants.adults} osobowy`
	let room = accommodation?.find(
		(room) =>
			room.adults === participants.adults &&
			room.children === participants.children &&
			room.name === `Pokój ${participants.adults} osobowy`,
	);

	// we're looking for best match
	if (!room) {
		room = accommodation?.find(
			(room) => room.adults === participants.adults && room.children === participants.children,
		);
	}
	// if best match not found we're looking for a room with surcharge for adults but with a place for child
	if (!room) {
		room = accommodation?.find(
			(room) => room.adults >= participants.adults && room.children === participants.children,
		);
	}
	// if secondary choice not found then we're looking for a room where we treat child as an adult
	if (!room) {
		room = accommodation?.find((room) => room.adults >= participants.adults + participants.children);
	}

	// if no room is found and there are accommodations available, it returns the first accommodation from the list
	if (!room && accommodation?.length) {
		room = accommodation[0];
	}

	return room;
};

export const findDescriptionForTemplate = (
	product: MatProduct,
	template: DescriptionTemplateHeaders,
	descriptions: Descriptions[],
): Descriptions => {
	const idToMatch = template.commonDescriptionHeaders.id;
	return findDescription(
		template.source === DescriptionTemplateHeadersSource.PRODUCT ? product.descriptions : descriptions,
		idToMatch,
	);
};

export const findDescription = (descriptions: Descriptions[], id: number): Descriptions | null =>
	descriptions?.find((v) => v.descriptionHeader.id === id) || null;

export const addStyleToLists = (htmlString: string): string => {
	return htmlString
		?.replace('<ul>', '<ul class="list-disc list-inside p-revert">')
		?.replace('<ol>', '<ol class="!list-decimal list-outside p-revert">');
};

export const mapDescriptionData = (
	templates: DescriptionTemplate[],
	descriptions: Descriptions[],
	activeProduct: MatProduct,
): OfferDescription[] => {
	const templateForActiveProduct = templates?.find((v) => v.productKind === activeProduct?.productKind.id);

	if (!templateForActiveProduct) return [];

	return templateForActiveProduct.descriptionTemplateHeaders
		.map((v) => {
			const description = findDescriptionForTemplate(activeProduct, v, descriptions);
			const sanitizeConfig: sanitize.IOptions = {
				allowedAttributes: {
					p: ['style'],
					span: ['style'],
				},
			};

			if (!description) return null;
			return {
				...v,
				description: {
					...description,
					description: addStyleToLists(sanitize(description?.description, sanitizeConfig)),
				},
			};
		})
		.filter((v) => !!v)
		.sort((a, b) => a.order - b.order);
};

export const calcBoardPrice = (
	boardPrice: number,
	configuratorNights: number,
	dateRange?: FilterValues['dateRange'],
): number => {
	if (!dateRange) {
		return boardPrice;
	}

	if (boardPrice === 0) {
		return 0;
	}

	const targetNights = dateRange.nights;
	const boardPricePerNight = boardPrice / configuratorNights;

	return boardPricePerNight * targetNights;
};

export const extractLocationIds = (obj: Location): number[] => {
	let ids = [obj.id];
	if (obj.parentLocation) {
		ids = ids.concat(extractLocationIds(obj.parentLocation));
	}
	return ids;
};

export const filterPromotionsByDateRange = (promotions: Promotion[], dateRange: TermDateRange): Promotion[] => {
	return promotions?.filter((promotion) => {
		return promotion?.promotionOffers?.length
			? promotion?.promotionOffers?.some((obj) => {
					return obj && dateRange ? checkIfPromotionIsAvailableForOffer(dateRange, obj.offer) : false;
				})
			: promotion;
	});
};

export const checkIfPromotionIsAvailableForOffer = (
	offerDateRange: TermDateRange,
	promotionDateRange: PromotionOfferDateRange,
): boolean => {
	const upperDateRange = new Date(promotionDateRange.upper);
	const lowerDateRange = new Date(promotionDateRange.lower);
	const departureTime = new Date(offerDateRange.transportFrom);

	if (isNaN(upperDateRange as unknown as number) || isNaN(lowerDateRange as unknown as number)) {
		throw new Error('You passed an invalid date');
	}

	return lowerDateRange <= departureTime && upperDateRange >= departureTime;
};

export const findBestPromo = (promotions: Promotion[]): Promotion => {
	return promotions?.reduce((acc, promo) => {
		if (promo.method === PromoMethod.PERCENTAGE && (acc === null || acc.methodValue < promo.methodValue)) {
			acc = promo;
		}
		return acc;
	}, null);
};

export const findAvailablePromotionsForOffer = (promotions: Promotion[], dateRange: TermDateRange): Promotion[] =>
	promotions?.filter((promotion) => {
		return promotion?.promotionOffers?.length
			? promotion.promotionOffers.some((obj) => {
					return obj && dateRange ? checkIfPromotionIsAvailableForOffer(dateRange, obj.offer) : false;
				})
			: promotion;
	});

export const findFilterPromotionAvailableForOffer = (
	promotions: Promotion[],
	filterValuePromotionId: number,
): Promotion => promotions.find((promotion) => promotion?.id === filterValuePromotionId);

export const getDiscount = (promotion: Promotion, price: number): number => {
	return promotion.method === PromoMethod.PERCENTAGE && promotion.methodValue > 0
		? price * (1 - promotion.methodValue / 1000)
		: price - promotion.methodValue;
};
