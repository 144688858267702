import {
	Convenience,
	DateRange,
	Descriptions,
	Multimedia,
	MultimediaWithOrder,
	ProductKind,
	Promotion,
	SurchargeProgram,
	Tag,
} from '@/offer/offer.model';
import { TermStatus } from '@/common/enum';

export type MatProduct = {
	id: number;
	name: string;
	code: string;
	productKind: ProductKind;
	organization: number;
	locations: Location[];
	multimedias: MultimediaWithOrder[];
	descriptions: Descriptions[];
	surchargeBoard: number;
	surchargeProgram: SurchargeProgram;
	conveniences: Convenience[];
	tags: Tag[];
	cities: Location[];
};

export const ROOM_ONE = 'Pokój 1';

export const OWN_TRANSPORT = 'Dojazd Własny';
export const TRANSPORT_BY_COACH = 'Autokarem';

export const DEFAULT_STOP = 'Poznań';

export const ADULTS_EXPRESS_PARTICIPANTS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];

export const CHILDREN_PARTICIPANTS_DEFAULT_OPTIONS = [0, 1, 2, 3];

export enum DescriptionUse {
	All = 'A',
	Couch = 'C',
}

export enum DescriptionTemplateKind {
	Description = 'D',
	Benefit = 'B',
}

export enum PriceOption {
	PERCENTAGE = 'P',
	VALUE = 'S',
}

export enum LocationKind {
	COUNTRY = 'CR',
	REGION = 'RG',
	CITY = 'CT',
}

export type Description = {
	id: number;
	description: string;
	use: DescriptionUse;
	descriptionHeaders: DescriptionHeader[];
};

export type DescriptionTemplate = {
	id: number;
	productKind: number;
	kind: DescriptionTemplateKind;
	descriptionTemplateHeaders: DescriptionTemplateHeaders[];
};

export interface OptionalTrip {
	id: number;
	description: string;
	isActive: boolean;
	name: string;
	optionalTripMultimedia: OptionalTripMultimedia[];
	optionalTripLocations: OptionalTripLocation[];
}

export interface OptionalTripMultimedia {
	id: number;
	multimedia: Multimedia;
	order: number;
}

export interface OptionalTripLocation {
	id: number;
	location: Location;
	order: number;
}

export interface Location {
	id: number;
	name: string;
	code: string;
	locationKind: LocationKind;
	parentLocation: Location | null;
}

export interface SellTerm {
	id: number;
	dateRange: DateRange;
	termPrice: number;
	nights: number;
	www: boolean;
	transportFrom: string;
	transportTo: string;
	promotionName?: string;
	status: TermStatus;
}

export interface SellTransport {
	id: number;
	location: string;
	address: string;
	timeDeparture: string;
	timeReturn: string;
	departureId: number;
	returnId: number;
	totalFee: number;
}

export interface Accommodation {
	id: number;
	name: string;
	code: string;
	adults: number;
	children: number;
	fee: number;
	roomTypeCount: number;
}

export interface ActivePriceSettingBoard {
	id: number;
	board: {
		id: number;
		name: string;
		code: string;
	};
	cost: number;
	price: number;
}

export interface ActivePriceSettingOptions {
	id: number;
	priceOption: {
		id: number;
		name: string;
		method: PriceOption;
	};
	methodValue: number;
	isActive: boolean;
}

export interface PriceSettings {
	activePriceSettingBoards: Array<ActivePriceSettingBoard>;
	activePriceSettingOptions: Array<ActivePriceSettingOptions>;
}

export interface PriceSettingsResult {
	calculatedPriceSetting: PriceSettings;
}

export type MatPromotion = Promotion;

export type DescriptionHeader = {
	id: number;
	name: string;
	header?: string;
	multimedia: Multimedia;
	comment?: string;
};

export enum DescriptionTemplateHeadersSource {
	GENERAL = 'O',
	PRODUCT = 'P',
}

export enum PromoMethod {
	PERCENTAGE = 'P',
	VALUE = 'V',
}

export type DescriptionTemplateHeaders = {
	id: number;
	order: number;
	commonDescriptionHeaders: DescriptionHeader;
	source: DescriptionTemplateHeadersSource;
	show: boolean;
};

export enum Services {
	SURCHARGE_TO_FREE_SPACE = 'Dopłata do wolnego miejsca',
	OWN_TRANSPORT = 'Dojazd Własny',
	CHILD_DISCROUNT = 'Zniżka na dziecko',
	INFANT_PRICE = 'Cena za niemowle',
}

export interface Participants {
	adults: number;
	children: number;
	childrenBirthday?: string[];
}

export interface TermDateRange {
	termId: number;
	upper: string;
	lower: string;
	termPrice: number;
	transportFrom: string;
	transportTo: string;
	nights: number;
}

export type FilterValues = {
	dateRange: TermDateRange;
	participants: Participants;
	roomType: {
		id: number;
		name: string;
		fee: number;
		adults: number;
		children: number;
		roomTypeCount: number;
	};
	transport: {
		ownTransport: boolean;
		stop?: SellTransport;
	};
	board: {
		id: number;
		name: string;
		price: number;
	};
	promotion: Promotion;
	nightsAmountFilter?: number;
};

export interface FilterValuesByRoom {
	[key: NonNullable<string>]: FilterValues;
}

export interface RoomType {
	id: number;
	name: string;
}

export interface AddedRoom {
	id: number;
	adultsNumber: number;
	boards: Array<FilterValues['board']>;
	childrenBirthdates: string[];
	kidsNumber: number;
	name: string;
	ownTransport: string | boolean;
	priceTransportDeparture: number;
	priceTransportReturn: number;
	roomType: Array<FilterValues['roomType']>;
	stops: Array<SellTransport>;
	dateRange: FilterValues['dateRange'];
	nightStay: number;
	termId: number;
	promotionId: number;
}

export interface OfferDescription {
	description: {
		description: string;
		descriptionHeader: DescriptionHeader;
		order: number;
		show: boolean;
	};
	id: number;
	order: number;
	commonDescriptionHeaders: DescriptionHeader;
	source: DescriptionTemplateHeadersSource;
}

export enum OfferTab {
	DESCRIPTION = 'description',
	BENEFITS = 'benefits',
	TERMS = 'terms',
	SCHEDULE = 'schedule',
	REST = 'rest',
	TRIPS = 'trips',
}
