import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

export enum OfferDropdownItemMode {
	MAT_TERM,
	PARTICIPANTS,
	ROOM_TYPES,
	DEPARTURE,
	BOARDS,
	PROMOTIONS,
}

@Component({
	selector: 'app-offer-dropdown-item',
	templateUrl: './offer-dropdown-item.component.html',
	styleUrls: ['./offer-dropdown-item.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferDropdownItemComponent {
	mode: OfferDropdownItemMode;

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('mode') set _mode(mode: OfferDropdownItemMode) {
		this.mode = mode;
		this.activeText$ = this.currentOfferFacade.getDropdownItemOption(mode);
	}

	@Input()
	productId: number;

	activeText$: Observable<string>;

	OfferDropdownItemMode = OfferDropdownItemMode;

	constructor(private readonly currentOfferFacade: CurrentOfferFacade) {}
}
