import { DateString } from '@/common/date-string-factory/date-string-factory';

export function countDays(date1: DateString, date2: DateString): number {
	const [d1, d2] = [new Date(date1), new Date(date2)].sort((a, b) => a.getTime() - b.getTime());

	d1.setHours(0, 0, 0, 0);
	d2.setHours(0, 0, 0, 0);

	let days = 0;

	while (d1 <= d2) {
		d1.setDate(d1.getDate() + 1);
		days++;
	}
	return days;
}
