import { Participants } from '@/_store/current-offer/current-offer.types';

export function adultsOrChildrenSufix(participants: Partial<Participants>): string {
	let text = '';

	if (participants?.adults === 1) {
		text += `1 dorosły `;
	} else if (participants?.adults > 1) {
		text += `${participants.adults} dorosłych `;
	}

	if (text !== '' && participants?.children > 0) {
		text += '+ ';
	}

	if (participants?.children === 1) {
		text += `1 dziecko`;
	} else if (participants?.children > 1) {
		text += `${participants?.children} dzieci`;
	}

	return text;
}
