import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'polishDate',
})
export class PolishDatePipe implements PipeTransform {
	transform(value: string): string {
		try {
			if (!value.trim().length) return '';
			const formatted = new Date(value);
			return Intl.DateTimeFormat('pl-PL').format(formatted);
		} catch (e) {
			if (e instanceof RangeError) {
				throw new Error('Wrong date type provided');
			} else {
				throw new Error('Unexpected error occurred');
			}
		}
	}
}
