/* eslint @typescript-eslint/explicit-module-boundary-types: 0 */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentOfferState } from './current-offer.reducer';
import {
	ADULTS_EXPRESS_PARTICIPANTS_OPTIONS,
	CHILDREN_PARTICIPANTS_DEFAULT_OPTIONS,
	DescriptionTemplateKind,
	FilterValues,
	OWN_TRANSPORT,
	ROOM_ONE,
	SellTerm,
	Services,
} from './current-offer.types';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import { sortAcc } from '@/_utils/numbers/sort-numbers';
import { removeDuplicatesFromArr } from '@/_utils/arrays/remove-duplicates-from-arr';
import { deepClone } from '@/_utils/objects/deep-clone';
import { countDays } from '@/_utils/datetime/count-days';
import { ProductKind } from '@/common/enum';
import {
	adultsParticipantsWhenSurchargeService,
	calcBoardPrice,
	calcChildrenDiscount,
	calcPromo,
	calcRoomPrice,
	calcTransportPrice,
	dropdownItemOption,
	mapDescriptionData,
	roomTypesForActiveParticipantsCondition,
} from './current-offer.utils';
import { DateString } from '@/common/date-string-factory/date-string-factory';

const selectCurrentOfferFeature = createFeatureSelector<CurrentOfferState>('currentOffer');

const selectDefaultDescriptions = createSelector(selectCurrentOfferFeature, (state) => state.descriptions);

const selectDescriptionTemplates = createSelector(selectCurrentOfferFeature, (state) => state.descriptionTemplates);

export const selectActiveProduct = createSelector(selectCurrentOfferFeature, (state) => state.product);

export const selectDescriptions = createSelector(selectDescriptionTemplates, (descriptionTemplates) =>
	descriptionTemplates?.filter((d) => d.kind === DescriptionTemplateKind.Description),
);

export const selectBenefits = createSelector(selectDescriptionTemplates, (descriptionTemplates) =>
	descriptionTemplates?.filter((d) => d.kind === DescriptionTemplateKind.Benefit),
);

export const selectSellTerms = (filterByNights = false) =>
	createSelector(selectCurrentOfferFeature, (state) => {
		if (!state?.sellTerms?.length) {
			return [];
		}

		return deepClone<SellTerm[]>(state?.sellTerms)
			.filter((sellTerm) =>
				state.filterValues[state.editedRoom].nightsAmountFilter
					? !filterByNights || sellTerm.nights === state.filterValues[state.editedRoom].nightsAmountFilter
					: true,
			)
			.sort((a, b) => new Date(a.dateRange.lower).getTime() - new Date(b.dateRange.lower).getTime());
	});

export const selectEditedRoom = createSelector(selectCurrentOfferFeature, (state) => state.editedRoom);

export const selectAllFilterValues = createSelector(selectCurrentOfferFeature, (state) => state.filterValues);

export const selectActiveFilterValues = createSelector(
	selectCurrentOfferFeature,
	(state) => state.filterValues[state.editedRoom],
);

export const selectActiveSellTermId = createSelector(
	selectActiveFilterValues,
	(filterValue) => filterValue?.dateRange?.termId ?? null,
);

export const selectActiveDateRange = createSelector(
	selectActiveFilterValues,
	(filterValue) => filterValue?.dateRange ?? null,
);

export const selectRoomTypes = createSelector(selectCurrentOfferFeature, (state) => state?.accommodation ?? []);

export const surchargeService = createSelector(
	selectCurrentOfferFeature,
	(state) =>
		state?.priceSettings?.activePriceSettingOptions?.find(
			(option) => option?.priceOption?.name === Services.SURCHARGE_TO_FREE_SPACE,
		) ?? null,
);

export const childrenDiscountService = createSelector(
	selectCurrentOfferFeature,
	(state) =>
		state?.priceSettings?.activePriceSettingOptions?.find(
			(option) => option?.priceOption?.name === Services.CHILD_DISCROUNT,
		) ?? null,
);

export const infantDiscountService = createSelector(
	selectCurrentOfferFeature,
	(state) =>
		state?.priceSettings?.activePriceSettingOptions?.find(
			(option) => option?.priceOption?.name === Services.INFANT_PRICE,
		) ?? null,
);

export const ownTransportService = createSelector(
	selectCurrentOfferFeature,
	(state) =>
		state?.priceSettings?.activePriceSettingOptions?.find(
			(option) => option?.priceOption?.name === Services.OWN_TRANSPORT,
		) ?? null,
);

export const selectRoomTypesForActiveParticipants = createSelector(
	selectCurrentOfferFeature,
	selectActiveFilterValues,
	surchargeService,
	(state, activeFilter, surchargeService) =>
		state?.accommodation?.filter((a) =>
			roomTypesForActiveParticipantsCondition(a, activeFilter, !!surchargeService),
		) ?? [],
);

export const selectAdultParticipants = createSelector(
	selectRoomTypes,
	selectActiveProduct,
	surchargeService,
	(roomTypes, product, surchargeService) =>
		!!surchargeService && roomTypes?.length
			? adultsParticipantsWhenSurchargeService(roomTypes)
			: roomTypes?.length
				? sortAcc(removeDuplicatesFromArr(roomTypes.map((roomType) => roomType.adults)))
				: product?.productKind?.id === ProductKind.EXPRESS
					? ADULTS_EXPRESS_PARTICIPANTS_OPTIONS
					: [],
);

export const selectChildrenParticipants = createSelector(selectRoomTypes, selectActiveProduct, (roomTypes, product) =>
	roomTypes?.length
		? sortAcc(
				removeDuplicatesFromArr([
					...roomTypes.map((roomType) => roomType.children),
					...CHILDREN_PARTICIPANTS_DEFAULT_OPTIONS,
				]),
			)
		: product?.productKind?.id === ProductKind.EXPRESS
			? CHILDREN_PARTICIPANTS_DEFAULT_OPTIONS
			: [],
);

export const selectMaxAmountOfPeople = createSelector(selectRoomTypes, selectActiveProduct, (roomTypes, product) =>
	product.productKind.id === ProductKind.EXPRESS
		? Math.max(...CHILDREN_PARTICIPANTS_DEFAULT_OPTIONS) + Math.max(...ADULTS_EXPRESS_PARTICIPANTS_OPTIONS)
		: roomTypes.reduce((acc, roomType) => {
				const sum = roomType.adults + roomType.children;
				if (sum > acc) {
					acc = sum;
				}

				return acc;
			}, 0),
);

export const selectIsChildrenBirthdaysValid = createSelector(selectCurrentOfferFeature, (state) => {
	const children = Object.values(state.filterValues).reduce((acc, value) => acc + value?.participants?.children, 0);

	const childrenBirthdays = Object.values(state.filterValues).reduce(
		(acc, value) =>
			acc + (value?.participants?.childrenBirthday ? value?.participants?.childrenBirthday.length : 0),
		0,
	);

	return children === childrenBirthdays;
});

export const selectIsConfiguratorValid = createSelector(selectCurrentOfferFeature, (state) => {
	const areFiltersValid = Object.values(state.filterValues).reduce((acc, value) => {
		if (!value.board || !value.transport) {
			acc = false;
		}

		if (!value.roomType && state?.product?.productKind.id !== ProductKind.EXPRESS) {
			acc = false;
		}

		return acc;
	}, true);

	return areFiltersValid;
});

export const selectSellTransport = createSelector(selectCurrentOfferFeature, (state) => state.sellTransport);

export const selectIsOwnTransport = createSelector(
	selectCurrentOfferFeature,
	(state) => state?.filterValues[state.editedRoom]?.transport?.ownTransport,
);

export const selectOwnTransport = createSelector(selectCurrentOfferFeature, (state) =>
	state?.priceSettings?.activePriceSettingOptions.find((option) => option?.priceOption?.name === OWN_TRANSPORT),
);

export const selectBoards = createSelector(
	selectCurrentOfferFeature,
	(state) => state?.priceSettings?.activePriceSettingBoards ?? [],
);

export const selectAllPromotions = createSelector(selectCurrentOfferFeature, (state) => state?.allPromotions ?? []);

export const selectPromotionCatalogs = createSelector(selectCurrentOfferFeature, (state) => state?.promotions ?? []);

export const selectPromotionDescription = createSelector(
	selectActiveFilterValues,
	(filterValues) => filterValues?.promotion?.name ?? '',
);

// select surcharge for free space in the room
export const selectSurcharge = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(selectCurrentOfferFeature, surchargeService, (state, surchargeService) => {
		if (
			!surchargeService ||
			!state?.filterValues[room]?.roomType ||
			!state?.filterValues[room]?.dateRange ||
			!state?.sellTerms
		) {
			return 0;
		}

		const termId = dateRange ? dateRange.termId : state.filterValues[room].dateRange.termId;

		const filterRoomId = state.filterValues[room].roomType.id;
		const roomType = state.accommodation.find((room) => room.id === filterRoomId);

		const term = state.sellTerms.find((sellTerm) => sellTerm.id === termId);

		const filterParticipants = state.filterValues[room].participants;
		return (
			term.nights *
			((roomType.adults + roomType.children - (filterParticipants.adults + filterParticipants.children)) *
				surchargeService.methodValue)
		);
	});

export const selectDropdownItemOption = (option: OfferDropdownItemMode, isLoggedIn = false) =>
	createSelector(selectCurrentOfferFeature, selectAllPromotions, (state, promotions) => {
		if (option === null) {
			return;
		}

		const filterValues = state?.filterValues[state.editedRoom];
		return dropdownItemOption(option, filterValues, isLoggedIn, promotions);
	});

export const selectDropdownItemIsDisabled = (option: OfferDropdownItemMode) =>
	createSelector(
		selectSellTerms(),
		selectBoards,
		selectRoomTypes,
		selectPromotionCatalogs,
		(sellTerms, boards, roomTypes, promos) => {
			switch (option) {
				case OfferDropdownItemMode.MAT_TERM:
					return sellTerms.length < 2;
				case OfferDropdownItemMode.BOARDS:
					return boards.length < 2;
				case OfferDropdownItemMode.DEPARTURE:
				case OfferDropdownItemMode.PARTICIPANTS:
					return false;
				case OfferDropdownItemMode.ROOM_TYPES:
					return roomTypes.length < 2;
				case OfferDropdownItemMode.PROMOTIONS:
					return promos.length < 1;
				default:
					const value: never = option;
					throw new Error(`There is no option like ${value}`);
			}
		},
	);

export const selectDropdownItemIsHidden = (option: OfferDropdownItemMode) =>
	createSelector(selectRoomTypes, (roomTypes) => {
		switch (option) {
			case OfferDropdownItemMode.ROOM_TYPES:
				return roomTypes.length < 1;
			default:
				return false;
		}
	});

export const selectTransportPrice = (room: string) =>
	createSelector(selectAllFilterValues, selectOwnTransport, (allFilterValues, ownTransport) => {
		return calcTransportPrice(allFilterValues[room], ownTransport);
	});

export const selectAdultCatalogPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(
		selectAllFilterValues,
		selectTransportPrice(room),

		(allFilterValues, transportPrice) => {
			const filterValue = allFilterValues[room];

			if (!filterValue || !filterValue.dateRange) {
				return;
			}

			if (filterValue?.participants?.adults === 0) {
				return 0;
			}

			const termPrice = dateRange ? dateRange.termPrice : filterValue.dateRange?.termPrice;
			const roomTypeFee = filterValue.roomType
				? (filterValue.dateRange.nights * filterValue.roomType.fee) / filterValue.participants.adults
				: 0;
			const boardPrice = calcBoardPrice(filterValue.board?.price ?? 0, filterValue.dateRange.nights, dateRange);

			return termPrice + boardPrice + transportPrice + roomTypeFee;
		},
	);

export const selectAdultPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(
		selectAllFilterValues,
		selectTransportPrice(room),
		selectAllPromotions,
		(allFilterValues, transportPrice, promotions) => {
			const filterValue = allFilterValues[room];

			if (!filterValue || !filterValue.dateRange) {
				return;
			}

			const termPrice = dateRange ? dateRange.termPrice : filterValue.dateRange?.termPrice;

			if (filterValue?.participants?.adults === 0) {
				return 0;
			}

			const roomTypeFee = filterValue.roomType
				? (filterValue.dateRange.nights * filterValue.roomType.fee) / filterValue.participants.adults
				: 0;
			const boardPrice = calcBoardPrice(filterValue.board?.price ?? 0, filterValue.dateRange.nights, dateRange);

			let promotablePrice = termPrice + boardPrice + roomTypeFee;
			let unPromotablePrice = 0;

			if (allFilterValues[room].transport?.ownTransport) {
				promotablePrice += transportPrice;
			} else {
				unPromotablePrice += transportPrice;
			}

			return calcPromo(promotablePrice, filterValue, promotions, dateRange) + unPromotablePrice;
		},
	);

export const selectChildCatalogPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(selectAllFilterValues, selectTransportPrice(room), (allFilterValues, transportPrice) => {
		const filterValue = allFilterValues[room];

		if (!filterValue || !filterValue.dateRange) {
			return;
		}

		if (filterValue?.participants?.children === 0) {
			return 0;
		}

		const termPrice = dateRange ? dateRange.termPrice : filterValue.dateRange?.termPrice;
		const boardPrice = calcBoardPrice(filterValue.board?.price ?? 0, filterValue.dateRange.nights, dateRange);

		return transportPrice + termPrice + boardPrice;
	});

export const selectChildPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(
		selectAllFilterValues,
		selectTransportPrice(room),
		childrenDiscountService,
		selectAllPromotions,
		(allFilterValues, transportPrice, childrenDiscount, promotions) => {
			const filterValue = allFilterValues[room];

			if (!filterValue || !filterValue.dateRange) {
				return;
			}

			if (filterValue?.participants?.children === 0) {
				return 0;
			}

			const termPrice = dateRange ? dateRange.termPrice : filterValue.dateRange?.termPrice;
			const boardPrice = calcBoardPrice(filterValue.board?.price ?? 0, filterValue.dateRange.nights, dateRange);

			let promotablePrice = termPrice + boardPrice;
			let unPromotablePrice = 0;

			if (allFilterValues[room].transport?.ownTransport) {
				promotablePrice += transportPrice;
			} else {
				unPromotablePrice += transportPrice;
			}

			return (
				unPromotablePrice +
				(!!filterValue?.promotion?.id
					? calcPromo(promotablePrice, filterValue, promotions, dateRange)
					: calcChildrenDiscount(termPrice + boardPrice, childrenDiscount))
			);
		},
	);

export const selectRoomFullPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(
		selectAdultPrice(room, dateRange),
		selectChildPrice(room, dateRange),
		selectSurcharge(room, dateRange),
		selectAllFilterValues,
		(adultPrice, childPrice, surcharge, allFilterValues) => {
			const filterValue = allFilterValues[room];
			return calcRoomPrice(adultPrice, childPrice, surcharge, {
				...filterValue,
				dateRange: dateRange ?? filterValue.dateRange,
			});
		},
	);

export const selectRoomPriceWithoutSurcharge = (room: string) =>
	createSelector(
		selectAdultPrice(room),
		selectChildPrice(room),
		selectAllFilterValues,
		(adultPrice, childPrice, allFilterValues) => calcRoomPrice(adultPrice, childPrice, 0, allFilterValues[room]),
	);

export const selectRoomCatalogPrice = (room: string, dateRange?: FilterValues['dateRange']) =>
	createSelector(
		selectAdultCatalogPrice(room, dateRange),
		selectChildCatalogPrice(room, dateRange),
		selectSurcharge(room, dateRange),
		selectAllFilterValues,
		(adultCatalogPrice, childCatalogPrice, surcharge, allFilterValues) => {
			const filterValue = allFilterValues[room];
			return calcRoomPrice(adultCatalogPrice, childCatalogPrice, surcharge, {
				...filterValue,
				dateRange: dateRange ?? filterValue.dateRange,
			});
		},
	);

export const selectSavings = (room: string) =>
	createSelector(
		selectAdultsSavings(room),
		selectChildrenSavings(room),
		(adultSavings, childrenSavings) => adultSavings + childrenSavings,
	);

export const selectAdultsSavings = (room: string) =>
	createSelector(
		selectAllFilterValues,
		selectAdultCatalogPrice(room),
		selectTransportPrice(room),
		selectAllPromotions,
		(allFilterValues, adultPrice, transportPrice, promotions) => {
			const filterValue = allFilterValues[room];

			if (!filterValue) {
				return;
			}

			if (filterValue?.participants?.adults === 0) {
				return 0;
			}

			const calcSavings = (price: number) =>
				price - calcPromo(price, filterValue, promotions, filterValue.dateRange);

			return filterValue?.participants?.adults * calcSavings(adultPrice - transportPrice);
		},
	);

export const selectChildrenSavings = (room: string) =>
	createSelector(
		selectAllFilterValues,
		childrenDiscountService,
		selectChildCatalogPrice(room),
		selectTransportPrice(room),
		selectAllPromotions,
		(allFilterValues, childrenDiscount, childPrice, transportPrice, promotions) => {
			const filterValue = allFilterValues[room];

			if (!filterValue) {
				return;
			}

			if (filterValue?.participants?.children === 0) {
				return 0;
			}

			const calcSavings = (price: number) =>
				price -
				(filterValue?.promotion?.id
					? calcPromo(price, filterValue, promotions, filterValue.dateRange)
					: calcChildrenDiscount(price, childrenDiscount));

			return filterValue?.participants?.children * calcSavings(childPrice - transportPrice);
		},
	);

export const selectActiveRoom = createSelector(selectCurrentOfferFeature, (state) => state.editedRoom);

export const selectRoomsToEdit = createSelector(selectCurrentOfferFeature, (state) => Object.keys(state.filterValues));

export const selectTransports = createSelector(selectAllFilterValues, (allFilterValues) =>
	Object.keys(allFilterValues).reduce((acc: Array<FilterValues['transport']>, key: string) => {
		const stopIsNotIncluded = acc.every(
			(v) =>
				allFilterValues[key].transport.ownTransport || v.stop?.id !== allFilterValues[key].transport.stop?.id,
		);
		if (stopIsNotIncluded) {
			acc.push(allFilterValues[key].transport);
		}

		return acc;
	}, []),
);

// TODO: to fix when reservation page is rewritten
export const selectRoomsForReservation = createSelector(
	selectCurrentOfferFeature,
	selectAdultCatalogPrice(ROOM_ONE),
	ownTransportService,
	(state, catalogPrice, ownTransportPriceSetting) => {
		const roomOne = state.filterValues[ROOM_ONE];

		const allRooms = Object.entries(state.filterValues);

		return {
			addedRooms: allRooms.map(([key, additionRoom], index) => ({
				id: index,
				adultsNumber: additionRoom.participants.adults,
				boards: [additionRoom.board],
				childrenBirthdates: additionRoom.participants.childrenBirthday ?? [],
				kidsNumber: additionRoom.participants.children,
				name: key,
				ownTransport: additionRoom.transport.ownTransport ?? '',
				priceTransportDeparture: additionRoom.transport?.stop?.totalFee
					? additionRoom.transport?.stop?.totalFee / 2
					: 0,
				priceTransportReturn: additionRoom.transport?.stop?.totalFee
					? additionRoom.transport?.stop?.totalFee / 2
					: 0,
				roomType: [additionRoom.roomType],
				stops: [additionRoom.transport?.stop],
				dateRange: additionRoom.dateRange,
				nightStay: additionRoom.dateRange.nights,
				termId: additionRoom.dateRange.termId,
				promotionId: additionRoom?.promotion?.id ?? null,
				wasTransportAvailable: ownTransportPriceSetting ? ownTransportPriceSetting.isActive : false,
			})),
			addedRoomsNumber: allRooms.length - 1,
			promotion: roomOne.promotion,
			term: roomOne.dateRange,
			nightStay: roomOne.dateRange.nights,
			product: state.product,
			days: countDays(
				<DateString>(roomOne.transport?.stop?.timeDeparture ?? roomOne.dateRange.lower),
				<DateString>(roomOne.transport?.stop?.timeReturn ?? roomOne.dateRange.upper),
			),
			productKind: state.product.productKind,
			adultsNumber: roomOne.participants.adults,
			kidsNumber: roomOne.participants.children,
			departureId: roomOne.transport?.stop?.departureId,
			returnId: roomOne.transport?.stop?.returnId,
			stop: roomOne.transport?.stop?.id,
			multimedia: state.product.multimedias,
			boardId: roomOne.board.id,
			boardPrice: roomOne.board.price,
			termId: { termId: roomOne.dateRange.termId },
			transport: {
				arrivalTime: roomOne.transport?.stop?.timeDeparture,
				departureId: roomOne.transport?.stop?.departureId,
				departureTime: roomOne.transport?.stop?.timeReturn,
				returnId: roomOne.transport?.stop?.returnId,
				fee: roomOne.transport?.stop?.totalFee,
			},
			organization: state.product.organization,
			catalogPrice,
		};
	},
);

export const selectDescriptionByHeaderName = (headerName: string) =>
	createSelector(selectOfferDescription, (description) => {
		return description?.find((v) => v.commonDescriptionHeaders.name === headerName);
	});

export const selectOfferDescription = createSelector(
	selectDescriptions,
	selectDefaultDescriptions,
	selectActiveProduct,
	(templates, descriptions, activeProduct) => mapDescriptionData(templates, descriptions, activeProduct),
);

export const selectOfferBenefits = createSelector(
	selectBenefits,
	selectDefaultDescriptions,
	selectActiveProduct,
	(templates, benefits, activeProduct) => mapDescriptionData(templates, benefits, activeProduct),
);

export const selectOfferDescriptionsAndBenefits = createSelector(
	selectOfferDescription,
	selectOfferBenefits,
	(descriptions, benefits) => descriptions.concat(benefits),
);

export const selectNightsAmountFilterForActiveRoom = createSelector(
	selectActiveFilterValues,
	(activeFilterValues) => activeFilterValues.nightsAmountFilter,
);

export const selectOfferMultimedia = createSelector(selectActiveProduct, (product) => product.multimedias);

export const selectOptionalTrip = createSelector(selectCurrentOfferFeature, (state) =>
	state.optionalTrip.filter((v) => v.isActive),
);

export const selectOfferLocationsDescriptions = createSelector(selectCurrentOfferFeature, (state) =>
	state.locations.flatMap((v) => v.locationDescriptions),
);
