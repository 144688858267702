<button
	class="w-full flex justify-center items-center px-4 py-2 rounded-md transition"
	[class]="buttonClass"
	[disabled]="disabled"
>
	<ng-content></ng-content>
	{{ placeholder }}
	<ng-container *ngIf="selected && selected > 2">
		({{ selected }}<ng-container *ngIf="subSelected && subSelected > 0"> +{{ subSelected }}</ng-container
		>)
	</ng-container>
</button>
