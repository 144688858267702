import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as currentOfferActions from './current-offer.actions';
import {
	selectActiveRoom,
	selectAdultParticipants,
	selectAdultPrice,
	selectAllFilterValues,
	selectBoards,
	selectChildPrice,
	selectChildrenParticipants,
	selectDropdownItemIsDisabled,
	selectDropdownItemOption,
	selectIsOwnTransport,
	selectSellTerms,
	selectPromotionCatalogs,
	selectPromotionDescription,
	selectRoomTypes,
	selectRoomsForReservation,
	selectRoomsToEdit,
	selectSavings,
	selectSellTransport,
	selectSurcharge,
	selectOwnTransport,
	selectActiveFilterValues,
	selectActiveProduct,
	selectRoomTypesForActiveParticipants,
	selectRoomFullPrice,
	selectRoomCatalogPrice,
	selectIsChildrenBirthdaysValid,
	selectIsConfiguratorValid,
	selectDropdownItemIsHidden,
	selectMaxAmountOfPeople,
	selectEditedRoom,
	selectAdultCatalogPrice,
	selectChildCatalogPrice,
	selectTransports,
	selectOfferDescription,
	selectDescriptionByHeaderName,
	selectOfferBenefits,
	selectOfferDescriptionsAndBenefits,
	selectActiveSellTermId,
	selectNightsAmountFilterForActiveRoom,
	selectOfferMultimedia,
	selectOptionalTrip,
	selectOfferLocationsDescriptions,
	selectRoomPriceWithoutSurcharge,
	selectAllPromotions,
} from './current-offer.selectors';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import { Observable, combineLatest, map, switchMap } from 'rxjs';
import { FilterValues, OfferDescription, SellTerm, TermDateRange } from './current-offer.types';

@Injectable({
	providedIn: 'root',
})
export class CurrentOfferFacade {
	adultsParticipants$ = this.store.select(selectAdultParticipants);
	childrenParticipants$ = this.store.select(selectChildrenParticipants);
	maxAmountOfPeople$ = this.store.select(selectMaxAmountOfPeople);
	roomTypes$ = this.store.select(selectRoomTypes);
	roomTypesForActiveParticipants$ = this.store.select(selectRoomTypesForActiveParticipants);
	stops$ = this.store.select(selectSellTransport);
	isOwnTransportEnabled$ = this.store.select(selectIsOwnTransport);
	isOwnTransportAvailable$ = this.store.select(selectOwnTransport);
	boards$ = this.store.select(selectBoards);
	promotionCatalogs$ = this.store.select(selectPromotionCatalogs);
	roomsForReservation$ = this.store.select(selectRoomsForReservation);
	activeRoom$ = this.store.select(selectActiveRoom);
	roomsToEdit$ = this.store.select(selectRoomsToEdit);
	promotionDescription$ = this.store.select(selectPromotionDescription);
	allFilterValues$ = this.store.select(selectAllFilterValues);
	activeFilterValues$ = this.store.select(selectActiveFilterValues);
	product$ = this.store.select(selectActiveProduct);
	activeSellTermId$ = this.store.select(selectActiveSellTermId);
	areChildrenBirthdaysValid$ = this.store.select(selectIsChildrenBirthdaysValid);
	isConfiguratorValid$ = this.store.select(selectIsConfiguratorValid);
	editedRoomChange$ = this.store.select(selectEditedRoom);
	transports$ = this.store.select(selectTransports);
	sellTransports$ = this.store.select(selectSellTransport);
	offerDescription$ = this.store.select(selectOfferDescription);
	offerBenefits$ = this.store.select(selectOfferBenefits);
	offerPrintDescriptions$ = this.store.select(selectOfferDescriptionsAndBenefits);
	nightsAmountFilterForActiveRoom$ = this.store.select(selectNightsAmountFilterForActiveRoom);
	multimedia$ = this.store.select(selectOfferMultimedia);
	optionalTrips$ = this.store.select(selectOptionalTrip);
	locationsDescriptions$ = this.store.select(selectOfferLocationsDescriptions);
	allPromotions$ = this.store.select(selectAllPromotions);

	surcharges$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getSurcharge(v)));
		}),
	);
	adultPrices$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getAdultPrice(v)));
		}),
	);
	childrenPrices$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getChildPrice(v)));
		}),
	);
	adultCatalogPrices$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getAdultCatalogPrice(v)));
		}),
	);
	childrenCatalogPrices$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getChildCatalogPrice(v)));
		}),
	);
	savings$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getSavings(v)));
		}),
	);
	roomsFullPrice$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getRoomFullPrice(v)));
		}),
	);
	roomsPriceWithoutSurcharge$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getRoomPriceWithoutSurcharge(v)));
		}),
	);
	roomsCatalogPrices$ = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getCatalogPrice(v)));
		}),
	);
	fullPrice$: Observable<number> = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getRoomFullPrice(v)));
		}),
		map((value) => value.reduce((acc, r) => acc + r, 0)),
	);
	fullCatalogPrice$: Observable<number> = this.allFilterValues$.pipe(
		switchMap((filterValue) => {
			return combineLatest(Object.keys(filterValue).map((v) => this.getCatalogPrice(v)));
		}),
		map((value) => value.reduce((acc, r) => acc + r, 0)),
	);

	constructor(private readonly store: Store) {}

	fetchMatProduct(id: number): void {
		this.store.dispatch(currentOfferActions.fetchMatProduct({ id }));
	}

	fetchLocations(ids: number[]): void {
		this.store.dispatch(currentOfferActions.fetchLocations({ ids }));
	}

	fetchDescription(): void {
		this.store.dispatch(currentOfferActions.fetchDescription());
	}

	fetchDescriptionTemplate(): void {
		this.store.dispatch(currentOfferActions.fetchDescriptionTemplate());
	}

	fetchSellTerm(params: string): void {
		this.store.dispatch(currentOfferActions.fetchSellTerm({ params }));
	}

	fetchPromotions(params: string, dateRange: TermDateRange): void {
		this.store.dispatch(currentOfferActions.fetchPromotions({ params, dateRange }));
	}

	fetchOptionalTrips(locationIds: number[]): void {
		this.store.dispatch(currentOfferActions.fetchOptionalTrip({ locationIds }));
	}

	clearCurrentOffer(): void {
		this.store.dispatch(currentOfferActions.clearCurrentOffer());
	}

	clearFilterValue(option: OfferDropdownItemMode): void {
		this.store.dispatch(currentOfferActions.clearFilterValue({ option }));
	}

	getSellTerms(filterByNights = false): Observable<SellTerm[]> {
		return this.store.select(selectSellTerms(filterByNights));
	}

	getDropdownItemOption(id: OfferDropdownItemMode, isLoggedIn = false): Observable<string> {
		return this.store.select(selectDropdownItemOption(id, isLoggedIn));
	}

	getDropdownItemIsDisabled(id: OfferDropdownItemMode): Observable<boolean> {
		return this.store.select(selectDropdownItemIsDisabled(id));
	}

	getDropdownItemIsHidden(id: OfferDropdownItemMode): Observable<boolean> {
		return this.store.select(selectDropdownItemIsHidden(id));
	}

	getSurcharge(room: string): Observable<number> {
		return this.store.select(selectSurcharge(room));
	}

	getAdultPrice(room: string): Observable<number> {
		return this.store.select(selectAdultPrice(room));
	}

	getAdultCatalogPrice(room: string): Observable<number> {
		return this.store.select(selectAdultCatalogPrice(room));
	}

	getChildPrice(room: string): Observable<number> {
		return this.store.select(selectChildPrice(room));
	}

	getChildCatalogPrice(room: string): Observable<number> {
		return this.store.select(selectChildCatalogPrice(room));
	}

	getSavings(room: string): Observable<number> {
		return this.store.select(selectSavings(room));
	}

	getCatalogPrice(room: string): Observable<number> {
		return this.store.select(selectRoomCatalogPrice(room));
	}

	getRoomFullPrice(room: string): Observable<number> {
		return this.store.select(selectRoomFullPrice(room));
	}

	getRoomPriceWithoutSurcharge(room: string): Observable<number> {
		return this.store.select(selectRoomPriceWithoutSurcharge(room));
	}

	getDescriptionByHeaderName(headerName: string): Observable<OfferDescription> {
		return this.store.select(selectDescriptionByHeaderName(headerName));
	}

	getTermFullPrice(room: string, term: FilterValues['dateRange']): Observable<number> {
		return this.store.select(selectRoomFullPrice(room, term));
	}

	getTermCatalogPrice(room: string, dateRange: FilterValues['dateRange']): Observable<number> {
		return this.store.select(selectRoomCatalogPrice(room, dateRange));
	}

	updateFilterTerm(dateRange: FilterValues['dateRange']): void {
		if (!dateRange) {
			return;
		}

		this.store.dispatch(currentOfferActions.updateFilterTerm({ dateRange }));
	}

	updateFilterParticipants(participants: FilterValues['participants']): void {
		this.store.dispatch(currentOfferActions.updateFilterParticipants({ participants }));
	}

	updateFilterChildrenBirthdays(childrenBirthday: FilterValues['participants']['childrenBirthday']): void {
		this.store.dispatch(
			currentOfferActions.updateFilterChildrenBirthday({
				childrenBirthday,
			}),
		);
	}

	updateFilterRoomType(roomType: FilterValues['roomType']): void {
		this.store.dispatch(currentOfferActions.updateFilterRoomType({ roomType }));
	}

	updateFilterBoard(board: FilterValues['board']): void {
		this.store.dispatch(currentOfferActions.updateFilterBoard({ board }));
	}

	updateFilterStop(transport: FilterValues['transport']): void {
		this.store.dispatch(currentOfferActions.updateFilterStop({ transport }));
	}

	updateFilterPromotion(promotion: FilterValues['promotion']): void {
		this.store.dispatch(currentOfferActions.updateFilterPromotion({ promotion }));
	}

	addNewRoom(): void {
		this.store.dispatch(currentOfferActions.addNewRoom());
	}

	updateEditedRoom(editedRoom: string): void {
		this.store.dispatch(currentOfferActions.updateEditedRoom({ editedRoom }));
	}

	removeRoom(roomName: string): void {
		this.store.dispatch(currentOfferActions.removeRoom({ roomName }));
	}

	addNightsAmountOption(nightsAmountFilter: number): void {
		this.store.dispatch(currentOfferActions.addNightsAmountOption({ nightsAmountFilter }));
	}

	removeNightsAmountOption(): void {
		this.store.dispatch(currentOfferActions.removeNightsAmountOption());
	}
}
