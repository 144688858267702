export function formatOskarDate(date: Date): string {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	const dayString = `${day < 10 ? '0' : ''}${day}`;
	const monthString = `${month < 10 ? '0' : ''}${month}`;

	return `${dayString}.${monthString}.${year}`;
}
