import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolishDatePipe } from '@/common/pipes/polish-date.pipe';
import { PricePipe } from '@/common/pipes/price.pipe';
import { OptimizedImagePipe } from '@/common/pipes/optimized-image.pipe';
import { TrustUrlPipe } from '@/common/pipes/trust-url.pipes';

const PIPES_TO_EXPORT = [PolishDatePipe, PricePipe, OptimizedImagePipe, TrustUrlPipe];

@NgModule({
	declarations: PIPES_TO_EXPORT,
	exports: PIPES_TO_EXPORT,
	imports: [CommonModule],
	providers: PIPES_TO_EXPORT,
})
export class PipesModule {}
