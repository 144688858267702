export enum Status {
	CONFIRMED = 'OK',
	OPTION = 'OP',
}

export enum ReservationStatus {
	CANCELED = 'CA',
	OPTION = 'OP',
	CONFIRMED = 'OK',
	CANCELED_BY_CLIENT = 'CC',
	CANCELED_WITHOUT_COST = 'CN',
	CANCELED_OBJECT = 'CO',
	CANCELED_TERM = 'CT',
	CANCELED_MULTIPLE = 'CM',
	REJECTED = 'RR',
	REJECTED_EXPIRATION = 'RE',
	REJECTED_CLIENT = 'RC',
	REJECTED_OBJECT = 'RO',
	REJECTED_TERM = 'RT',
	REJECTED_PROMOTION = 'RP',
	WAIT_FOR_DECISION = 'WD',
	REJECTED_SELLER = 'RS',
}

export const CancellationStatuses = [
	ReservationStatus.CANCELED,
	ReservationStatus.CANCELED_BY_CLIENT,
	ReservationStatus.CANCELED_WITHOUT_COST,
	ReservationStatus.CANCELED_OBJECT,
	ReservationStatus.CANCELED_TERM,
	ReservationStatus.CANCELED_MULTIPLE,
	ReservationStatus.REJECTED_EXPIRATION,
	ReservationStatus.REJECTED_CLIENT,
	ReservationStatus.REJECTED_OBJECT,
	ReservationStatus.REJECTED_TERM,
	ReservationStatus.REJECTED_PROMOTION,
	ReservationStatus.REJECTED_SELLER,
];

export enum UserKind {
	CHILD = 'C',
	ADULT = 'A',
}

export enum PaymentTermKind {
	ADVANCE = 300,
	REST = 700,
	FULL = 1000,
}

export enum PaymentStatus {
	SETTLED = 'S',
	UNSETTLED = 'U',
	PARTIALLY_SETTLED = 'P',
	OVERSETTLED = 'O',
}

export enum ContactDestiny {
	CONTACT = 'CNTCT',
}

export enum Currency {
	POLISH_ZLOTY = 'PLN',
	EURO = 'EUR',
}

export enum ProductKind {
	EXPRESS = 3,
	NONAME = 6,
	OBJECTNONAME = 5,
	ROUNDTRIP = 2,
	TRANSPORT = 4,
	VOCATION = 1,
}

export enum MessengerStatus {
	DISABLED = 'disabled',
	PENDING = 'pending',
	ENABLED = 'enabled',
}
export enum CountKind {
	PERCENTAGE = 'P',
	VALUE = 'V',
	REMAINING_TO_THE_GIVEN_PERCENTAGE = 'R',
}

export enum AvailabilityKind {
	TRANSPORT = 'transport',
	ROOM = 'room',
}

export enum SellChannel {
	OFFICE = 'office',
	WWW = 'www',
	MERLINX = 'merlinx',
}

export enum TermStatus {
	CONFIRMED = 100,
}
