<ng-container *ngIf="mode !== undefined">
	<app-mat-term-item
		*ngIf="mode === OfferDropdownItemMode.MAT_TERM"
		[activeText]="activeText$ | async"
	></app-mat-term-item>
	<app-participants *ngIf="mode === OfferDropdownItemMode.PARTICIPANTS" [productId]="productId"></app-participants>
	<app-room-types
		*ngIf="mode === OfferDropdownItemMode.ROOM_TYPES"
		[productId]="productId"
		[activeText]="activeText$ | async"
	></app-room-types>
	<app-departure *ngIf="mode === OfferDropdownItemMode.DEPARTURE" [activeText]="activeText$ | async"></app-departure>
	<app-boards
		*ngIf="mode === OfferDropdownItemMode.BOARDS"
		[productId]="productId"
		[activeText]="activeText$ | async"
	></app-boards>
	<app-promotion-catalogs
		*ngIf="mode === OfferDropdownItemMode.PROMOTIONS"
		[activeText]="activeText$ | async"
	></app-promotion-catalogs>
</ng-container>
