import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.css'],
})
export class ButtonComponent {
	@Input() placeholder = '';
	@Input() selected: number | null = 0;
	@Input() subSelected: number | null = 0;
	@Input() disabled = false;

	@Input() set type(value: string) {
		switch (value) {
			case 'primary':
				this.buttonClass =
					'border-[1px] border-accentColor bg-accentColor text-white hover:bg-accentColorDarker ';
				break;
			case 'secondary':
				this.buttonClass = 'border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-[#f7f7f7] justify-evenly';
				break;
			case 'datepicker':
				this.buttonClass =
					'border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-[#f7f7f7] justify-between px-1';
				break;
			case 'slider':
				this.buttonClass =
					'border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-[#f7f7f7] justify-evenly !rounded-full !h-auto !w-auto !p-3 ';
				break;
			case 'sliderColor':
				this.buttonClass =
					'border-[1px] border-mainColor bg-accentColor hover:bg-accentColorDarker justify-evenly !rounded-full !h-auto !w-auto !p-3 ';
				break;
			case 'filter-button':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-[#f7f7f7] justify-evenly text-mainColor text-xs md:text-base mt-3 overflow-hidden overflow-ellipsis whitespace-nowrap !block font-bold';
				break;
			case 'filter-button-secondary':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-[#f7f7f7] justify-evenly text-mainColor text-xs md:text-base mt-3 overflow-hidden overflow-ellipsis whitespace-nowrap !block ';
				break;
			case 'filter-button-primary':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-accentColor hover:bg-accentColorDarker justify-evenly text-white font-extrabold text-xs md:text-sm py-2';
				break;
			case 'filter-button-disabled':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-[#cccccc] justify-evenly text-white font-extrabold text-xs md:text-sm py-2';
				break;

			case 'box-button':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-accentColor hover:bg-[#ffce6a] justify-evenly text-white font-extrabold text-sm md:text-lg  mt-0 py-2 mt-3 uppercase';
				break;

			case 'box-button-added':
				this.buttonClass =
					'!rounded-3xl border-[1px] border-[rgba(0,0,0,0.12)] bg-mainColor hover:bg-mainColor/[85%] justify-evenly text-white font-extrabold text-sm md:text-lg  mt-0 py-2 mt-3 uppercase';
				break;

			case 'box-button-secondary':
				this.buttonClass =
					'!rounded-3xl text-accentColor border-[1px] border-[rgba(0,0,0,0.12)] bg-white hover:bg-oskarGrey justify-evenly font-extrabold text-lg  mt-0 py-2 mt-3 uppercase';
				break;

			case 'box-button-secondary-disabled':
				this.buttonClass =
					'!rounded-3xl text-white border-[1px] border-[rgba(0,0,0,0.12)] bg-[#cccccc] justify-evenly font-extrabold text-lg  mt-0 py-2 mt-3 uppercase';
				break;
			case 'footer':
				this.buttonClass =
					'!rounded-3xl bg-accentColor hover:bg-accentColorDarker justify-evenly text-white text-xl w-44 font-semibold px-5 !py-3';
		}
	}

	buttonClass = '';
}
